import { styled } from "styled-components";
import device from "../../theme/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
`;

export const Icon = styled.img`
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
  }
`;

export const SubTitle = styled.p`
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #514945;
  margin-bottom: 56px;

  @media ${device.mobileL} {
    font-size: 14px;
    letter-spacing: 0.28px;
    margin-bottom: 40px;
  }
`;
