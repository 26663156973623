import axios from "axios";

export const submitForm = async (data: any) => {
  try {
    console.log(data, "<<<< before submit");
    const api = process.env.REACT_APP_API;
    const res = await axios.post(api + "/create-lead-rag", { data: data });
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};
