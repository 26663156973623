import { useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { StepWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import useCheckPath from "../../utils/useCheckPath";

const Form = () => {
  const navigate = useNavigate();

  const valid = useCheckPath();
  const { step } = useAppSelector((state) => state.step);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!valid) {
      navigate("/");
    }
  });

  const stepsArray = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />];

  return <StepWrapper>{stepsArray[step - 1]}</StepWrapper>;
};

export default Form;
