import Banner from "../../components/Banner";
import Form from "../../components/Form";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import { Inner, Section } from "./styles";

const FormPage = () => {
  return (
    <Section>
      <Header $form={true} />
      <Inner>
        <ProgressBar />
        <Form />
        <Banner $form={true} />
      </Inner>
    </Section>
  );
};

export default FormPage;
