import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import {
  Container,
  Detail,
  Img,
  Item,
  Main,
  Row,
  Section,
  Title,
} from "./styles";

interface OurTeamProps {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  handleHeroScroll: () => void;
}

const bullets = [
  "Nobu",
  "Cipriani",
  "Novikov",
  "Monkey House",
  "Ping Pong",
  "Angus Steak House",
  "Chotto Matte",
  "Bo Deans",
  "Masala Zone",
  "Zizzi",
  "Black and Blue",
  "Novus",
  "Revolution Bars",
  "Stonegate",
  "Roast",
  "Ottolenghi",
  "Busaba",
  "San Carlo",
  "Vapiano",
  "Tortilla",
];

const OurTeamSection: React.FC<OurTeamProps> = ({
  scrollRef,
  handleHeroScroll,
}) => {
  return (
    <Section ref={scrollRef}>
      <Container>
        <Title>
          <h1>Your restaurant can rely upon us.</h1>
          <p>
            Our expert team have amassed years of experience working with the
            restaurant and property sectors, making sure the selling and
            transfer process is done exactly to your taste.
          </p>
        </Title>
        <Main>
          <Row>
            <Item>
              <Img src={imgs.david} alt="staff" />
              <Detail>
                <h1>David Abramson (CEO & Founder)</h1>
                <p>
                  With 25-years’ experience in the leisure sector, David is one
                  of London’s most well-known hospitality advisers. Over the
                  years he has supported key acquisitions /growth/support for
                  companies including;
                </p>
                <ul>
                  {bullets.map((str: string) => {
                    return (
                      <li>
                        <img src={imgs.bullet} />
                        <p>{str}</p>
                      </li>
                    );
                  })}
                </ul>
                <p>
                  As CEO of Cedar Dean, David drives the vision and is
                  responsible for advising companies on their corporate journey,
                  as well as seeking new business opportunities. David
                  originates from a very entrepreneurial family and helping
                  businesses is very close to his heart.
                </p>
                <p>
                  David is also the chair of the Commercial Tenants Association,
                  a key player in lobbying for and supporting commercial
                  tenants.
                </p>
                <ReusableButton
                  $primary={true}
                  name="Start Free Valuation"
                  onClick={() => handleHeroScroll()}
                />
              </Detail>
            </Item>
            <Item>
              <Img src={imgs.marc} alt="staff" />
              <Detail>
                <h1>Marc Bevan (Co-founder)</h1>
                <p>
                  Marc first connected with the hospitality industry while
                  running his own construction company which catered primarily
                  to the hospitality sector.
                </p>
                <p>
                  Soon after, Marc was approached by Nobu London to oversee the
                  construction of multiple locations across Europe, before
                  moving into operations for Ping Pong restaurants, launching 10
                  locations over a 2-year period.
                </p>
                <p>
                  Marc then joined the Roast London team leading to his purchase
                  of the restaurant and brand in 2019.
                </p>
                <ReusableButton
                  $primary={true}
                  name="Start Free Valuation"
                  onClick={() => handleHeroScroll()}
                />
              </Detail>
            </Item>
          </Row>
          {/* <Row>
            <Item>
              <Img
                src={imgs.salesHead}
                alt="staff"
              />
              <Detail>
                <h1>Martha Lennon (Head of Sales)</h1>
                <p>
                  Having seen through the final sales of hundreds of property
                  acquisitions and restaurant transfers, Martha is well-versed
                  in the entire acquisition process and has been a main
                  point-of-contact whenever needed.
                </p>
              </Detail>
            </Item>
            <Item>
              <Img
                src={imgs.adviser}
                alt="staff"
              />
              <Detail>
                <h1>Ben Watson (Senior Adviser)</h1>
                <p>
                  Working alongside the RAG team, Ben showcases an excellent
                  understanding of what makes a restaurant truly valuable and
                  special to its owner, and uses his expert knowledge of the
                  industry to help you get a fair price.
                </p>
              </Detail>
            </Item>
          </Row> */}
        </Main>
      </Container>
    </Section>
  );
};

export default OurTeamSection;
