import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import FormPage from "./pages/FormPage";
import ThankyouPage from "./pages/ThankyouPage";
import SorryPage from "./pages/SorryPage";
import NotFoundPage from "./pages/NotFoundPage";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LandingPage />}
        />
        <Route
          path="/form"
          element={<FormPage />}
        />
        <Route
          path="/thank-you"
          element={<ThankyouPage />}
        />
        <Route
          path="/sorry"
          element={<SorryPage />}
        />
        <Route
          path="/404"
          element={<NotFoundPage />}
        />
        <Route
          path="/*"
          element={<NotFoundPage />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
