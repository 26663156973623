import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Section = styled.section`
  background: ${colors.brown};
  color: ${colors.tertiary};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 160px 48px;
  gap: 56px;

  @media ${device.tabletL} {
    padding: 56px 24px 48px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 880px;
  text-align: center;

  > h1 {
    font-size: 48px;
    padding: 0px 20px;
  }

  > p {
    font-size: 18px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      padding: 0px 12px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
`;

export const Row = styled.div`
  display: flex;
  gap: 80px;

  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  max-width: 1120px;
  gap: 40px;
  align-items: flex-start;

  @media (max-width: 1055px) {
    flex-direction: column;
    align-items: center;
  }

  @media ${device.mobileL} {
    gap: 24px;
  }
`;

export const Img = styled.img`
  width: 336px;
  height: auto;
  object-fit: contain;

  @media ${device.mobileL} {
    width: 324px;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 24px;
    max-width: 730px;

    li {
      display: flex;
      gap: 8px;

      p {
        color: var(--Complimentary, #ece7d4);
        font-family: "articulat-cf";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        @media (max-width: 500px) {
          color: var(--Complimentary, #ece7d4);

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
        }
      }
    }
  }

  > h1 {
    font-size: 32px;
  }

  > p {
    font-size: 18px;
  }

  @media (max-width: 1055px) and (min-width: 500px) {
    align-items: center;
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 24px;
    }

    > p {
      font-size: 14px;
      padding-right: 14px;
    }
  }
`;
