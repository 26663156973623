import ReusableButton from "../../../reusable/buttons";
import { QuestionContainer } from "../styles";
import { ButtonWrapper, Title } from "./styles";
import { updateStep } from "../../../redux/features/stepSlice";
import { updateProgress } from "../../../redux/features/progressSlice";
import CalenderCom from "../../Calendar";
import { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { updateForm } from "../../../redux/features/formSlice";
import moment from "moment";

const Step3 = () => {
  const [date, setDate] = useState(new Date());
  const dispatch = useAppDispatch();

  const handleClick = () => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    dispatch(updateForm({ leaseRunOutDate: formattedDate }));
    dispatch(updateStep(4));
    dispatch(updateProgress(68));
  };
  return (
    <QuestionContainer>
      <Title>When does your lease run out?</Title>
      <CalenderCom
        date={date}
        setDate={setDate}
      />
      <ButtonWrapper>
        <ReusableButton
          $primary={true}
          name="Continue"
          $mwidth="207px"
          onClick={() => handleClick()}
        />
      </ButtonWrapper>
    </QuestionContainer>
  );
};

export default Step3;
