import { MouseEventHandler, useState } from "react";
import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import size from "../../theme/devices/sizes";
import useWindowSize, { Size } from "../../utils/useWindowSize";
import Banner from "../Banner";
import Header from "../Header";

import {
  ButtonWrapper,
  Container,
  InputLabel,
  InputWrapper,
  LocationIcon,
  PostcodeInput,
  Section,
  SubTitle,
  Title,
} from "./styles";
import { useDispatch } from "react-redux";
import { updateForm } from "../../redux/features/formSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { Notify } from "notiflix";

interface HeroProps {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  handleGuaranteeScroll?: () => void;
}

const Hero: React.FC<HeroProps> = ({ scrollRef, handleGuaranteeScroll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useAppSelector((state) => state.form);
  const windowSize: Size = useWindowSize();
  const mobileL = parseInt(size.mobileL.slice(0, -2));
  const tabletL = parseInt(size.tabletL.slice(0, -2));
  const [postcode, setPostcode] = useState<string>("");

  const handlePostcode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPostcode(value.toUpperCase().trim());
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (postcode.length === 0) {
      return Notify.failure("Please enter your postcode");
    } else if (postcode.length < 5 || postcode.length > 7) {
      return Notify.failure("Please provide a valid postcode.");
    } else {
      window.scroll(0, 0);

      dispatch(
        updateForm({
          details: { ...form.details, businessPostcode: postcode },
        }),
      );
      navigate("/form");
    }
  };

  return (
    <Section ref={scrollRef}>
      <Container>
        <Header
          $form={false}
          handleGuaranteeScroll={handleGuaranteeScroll}
        />
        {windowSize.width! <= tabletL && (
          <SubTitle>
            Achieve a <strong>fair price</strong> for trading your hospitality
            business
          </SubTitle>
        )}
        <Title>Sell Your Restaurant With Ease.</Title>
        <InputLabel>Enter your restaurant postcode:</InputLabel>
        <InputWrapper>
          <LocationIcon
            src={imgs.location}
            alt="location"
          />
          <PostcodeInput
            placeholder="e.g.AB1 2CD"
            name="postcode"
            value={postcode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePostcode(e)
            }
          />
          {windowSize.width! >= mobileL && (
            <ButtonWrapper>
              <ReusableButton
                $primary={true}
                name="Sell My Restaurant"
                width="175px"
                height="51px"
                fontSize="16px"
                onClick={handleClick}
              />
            </ButtonWrapper>
          )}
        </InputWrapper>
        {windowSize.width! <= mobileL && (
          <ButtonWrapper>
            <ReusableButton
              $primary={true}
              name="Sell My Restaurant"
              width="175px"
              height="51px"
              fontSize="14px"
              onClick={handleClick}
            />
          </ButtonWrapper>
        )}
        <Banner $form={false} />
      </Container>
    </Section>
  );
};

export default Hero;
