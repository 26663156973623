import { useState } from "react";
import { QuestionContainer } from "../styles";
import {
  AddressWrapper,
  ButtonWrapper,
  ChangeAddress,
  Dropdown,
  DropdownWrapper,
  FormWrapper,
  Input,
  InputsWrapper,
  SearchBtn,
  SelectedAddress,
  SubTitle,
  Title,
} from "./styles";
import { Details, updateForm } from "../../../redux/features/formSlice";
import ReusableButton from "../../../reusable/buttons";
import { updateProgress } from "../../../redux/features/progressSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { submitForm } from "../../../utils/submitForm";
import { Loading, Notify } from "notiflix";
import { phoneValidation } from "../../../utils/phone-validation";
import { emailValidation } from "../../../utils/email-validation";

const Step5: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useAppSelector((state) => state.form);
  const [details, setDetails] = useState<Details>(form.details);
  const [lookupResults, setLookupResults] = useState<object[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const trimPhone = value.replace(/\D/g, "");
      setDetails((prev) => ({
        ...prev,
        phone: trimPhone,
      }));
    } else if (name === "email") {
      const trimEmail = value.trim();
      setDetails((prev) => ({
        ...prev,
        email: trimEmail,
      }));
    } else {
      setDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSearch = () => {
    const trimPostcode = details.businessPostcode.toUpperCase().trim();
    if (trimPostcode.length < 5 || trimPostcode.length > 7) {
      return Notify.failure("Please provide a valid postcode.");
    }
    window.predictiveAddress.search(
      "GB",
      details.businessPostcode,
      "",
      "",
      [
        new window.data8.option("IncludeMR", "false"),
        new window.data8.option("IncludeNYB", "false"),
        new window.data8.option("PreferredLanguage", "en"),
      ],
      (result: any) => {
        if (result?.Results) {
          setLookupResults(result?.Results);
          setShowDropdown(true);
        }
        if (
          result?.Results.length === 0 ||
          details.businessPostcode.length < 3
        ) {
          setShowDropdown(false);
        }
      },
    );
  };

  const handleAddressSelection = (e: any) => {
    const value: any = (e.target as HTMLButtonElement).getAttribute(
      "data-value",
    );
    setDetails({ ...details, businessAddress: value });
    setLookupResults([]);
    setShowDropdown(false);
  };

  const handleChangeAddress = () => {
    setDetails((prev) => ({ ...prev, businessAddress: "" }));
  };

  const handleSubmit = async () => {
    if (!details.firstName) {
      return Notify.failure("Please provide your first name.");
    }
    if (!details.lastName) {
      return Notify.failure("Please provide your last name.");
    }

    if (!details.email) {
      return Notify.failure("Please provide your email address.");
    }
    const emailRes = await emailValidation(details.email);
    if (!emailRes) {
      return Notify.failure("Please provide a valid email address.");
    }

    if (!details.phone) {
      return Notify.failure("Please provide your phone number.");
    }
    const phoneRes = await phoneValidation(parseInt(details.phone));
    if (!phoneRes) {
      return Notify.failure("Please provide a valid phone number.");
    }

    if (!details.businessName) {
      return Notify.failure("Please provide your business name.");
    }
    if (!details.businessAddress) {
      return Notify.failure("Please provide your business address.");
    }
    if (!details.businessPostcode) {
      return Notify.failure("Please provide your postcode.");
    }

    Loading.dots();

    dispatch(updateForm({ ...form, details: details }));
    const payload = { ...form, details: details };

    const res = await submitForm(payload);
    Loading.remove();
    if (res.success) {
      dispatch(updateProgress(100));
      navigate("/thank-you");
    } else if (res.message === "Email address already in use.") {
      return Notify.failure("Email address already in use.");
    } else if (res.message === "Phone number already in use.") {
      return Notify.failure("Phone number already in use.");
    } else {
      return Notify.failure("Something went wrong please try again later.");
    }
  };

  return (
    <QuestionContainer>
      <Title>Almost done! Just a few more things</Title>
      <SubTitle>
        Please enter your details to submit your sale request.
      </SubTitle>
      <FormWrapper>
        <InputsWrapper>
          <Input
            placeholder="First name"
            name="firstName"
            value={details.firstName}
            onChange={handleChange}
          />
          <Input
            placeholder="Surname"
            name="lastName"
            value={details.lastName}
            onChange={handleChange}
          />
        </InputsWrapper>
        <Input
          placeholder="Email address"
          name="email"
          value={details.email}
          onChange={handleChange}
        />
        <InputsWrapper>
          <Input
            placeholder="Phone number"
            name="phone"
            value={details.phone}
            onChange={handleChange}
            inputMode="numeric"
          />
          <Input
            placeholder="Business name"
            name="businessName"
            value={details.businessName}
            onChange={handleChange}
          />
        </InputsWrapper>
        <AddressWrapper>
          {details.businessAddress.length > 0 ? (
            <>
              <SelectedAddress>{details.businessAddress}</SelectedAddress>
              <ChangeAddress onClick={handleChangeAddress}>
                Search for address again
              </ChangeAddress>
            </>
          ) : (
            <Input
              placeholder="Business postcode"
              name="businessPostcode"
              value={details.businessPostcode}
              onChange={handleChange}
            />
          )}

          {details.businessAddress.length === 0 && (
            <SearchBtn onClick={handleSearch}>Search</SearchBtn>
          )}
          {showDropdown && (
            <DropdownWrapper>
              <Dropdown>
                {lookupResults.map((address: any, index) => {
                  return (
                    <li
                      key={index}
                      onClick={handleAddressSelection}
                      data-value={address.label}
                    >
                      {address.label}
                    </li>
                  );
                })}
              </Dropdown>
            </DropdownWrapper>
          )}
        </AddressWrapper>
      </FormWrapper>
      <ButtonWrapper>
        <ReusableButton
          $primary={true}
          name="Submit Enquiry"
          onClick={() => handleSubmit()}
          $mwidth="224px"
        />
        <p>*Subject to full background checks</p>
      </ButtonWrapper>
    </QuestionContainer>
  );
};

export default Step5;
