export const colors = {
  primary: "#FF4B12",
  secondary: "#D4BE8C",
  tertiary: "#ECE7D4",
  text: "#241F1C",
  green: "#53B843",
  BtnHover: "#FF7F56",
  white: "#FFFFFF",
  grey: "#A1A1A1",
  brown: "#241F1C",
};
