import ReusableButton from "../../reusable/buttons";
import { Container, Heading, Texts, Section, Main, Paragraph } from "./styles";

interface GuaranteeSectionProps {
  handleHeroScroll: () => void;
}

const GuaranteeSection: React.FC<GuaranteeSectionProps> = ({
  handleHeroScroll,
}) => {
  return (
    <Section>
      <Container>
        <Main>
          <Paragraph>
            <Heading>Guarantee a smooth sale.</Heading>
            <Texts>
              <p>
                The food sector is a congested space, especially in the premium
                restaurant service in and around London.
              </p>
              <br />
              <p>
                By showcasing to us the unique selling points and highlighting
                just how much your restaurant means to you, we can purchase your
                business from you at a price that benefits you.
              </p>
              <br />
              <p>
                Demonstrating how resilient, adaptable to gastronomic trends and
                financially healthy your restaurant is, can increase what will
                already be a fantastic offer – heightening your chances of a
                successful sale.
              </p>
            </Texts>
          </Paragraph>

          <ReusableButton
            $primary={true}
            name="Start My Sale"
            onClick={() => handleHeroScroll()}
            $mwidth="215px"
          />
        </Main>
        <Main>
          <Paragraph>
            <Heading>In the hands of the experts.</Heading>
            <Texts>
              <p>
                The Restaurant Acquisition Group (RAG) is one of the UK’s most
                trusted teams of restaurant property experts, helping owners and
                leaseholders easily sell their restaurant at a fair price.
              </p>
              <br />
              <p>
                Having assisted hundreds of UK restaurateurs in selling their
                business, we take immense pride in making the entire process as
                smooth as possible for you, and ensuring that you receive the
                right price that completely rewards you for all the hard work
                you’ve put into your restaurant.
              </p>
            </Texts>
          </Paragraph>

          <ReusableButton
            $primary={true}
            name="Get Expert Help Now"
            onClick={() => handleHeroScroll()}
            $mwidth="215px"
          />
        </Main>
      </Container>
    </Section>
  );
};

export default GuaranteeSection;
