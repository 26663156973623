import { useEffect, useRef } from "react";
import Footer from "../../components/Footer";
import GuaranteeSection from "../../components/GuaranteeSection";
import Hero from "../../components/Hero";
import IntroSection from "../../components/IntroSection";
import StepsSection from "../../components/StepsSection";
import { Section } from "./styles";
import { resetStep } from "../../redux/features/stepSlice";
import { resetForm } from "../../redux/features/formSlice";
import { useAppDispatch } from "../../redux/hooks";
import OurTeamSection from "../../components/OurTeamSection";
import ReviewsSection from "../../components/ReviewsSection";
import { resetProgress } from "../../redux/features/progressSlice";
import BenefitSection from "../../components/BenefitSection";

const LandingPage = () => {
  const dispatch = useAppDispatch();
  const scrollToHero = useRef<HTMLDivElement | null>(null);
  const scrollToOurTeam = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(resetProgress());
    dispatch(resetStep());
    dispatch(resetForm());
  });

  const handleHeroScroll = () => {
    if (scrollToHero.current) {
      scrollToHero.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleGuaranteeScroll = () => {
    if (scrollToOurTeam.current) {
      scrollToOurTeam.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Section>
      <Hero
        scrollRef={scrollToHero}
        handleGuaranteeScroll={handleGuaranteeScroll}
      />
      <IntroSection handleHeroScroll={handleHeroScroll} />
      <StepsSection handleHeroScroll={handleHeroScroll} />
      <GuaranteeSection handleHeroScroll={handleHeroScroll} />
      {/* <BenefitSection handleHeroScroll={handleHeroScroll} /> */}
      <OurTeamSection
        scrollRef={scrollToOurTeam}
        handleHeroScroll={handleHeroScroll}
      />
      <ReviewsSection handleHeroScroll={handleHeroScroll} />

      <Footer />
    </Section>
  );
};

export default LandingPage;
