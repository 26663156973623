import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Section = styled.section`
  color: ${colors.text};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 160px 48px;
  gap: 74px;

  @media ${device.tabletL} {
    padding: 56px 24px 56px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 800px;
  text-align: center;

  > h1 {
    font-size: 48px;
  }

  > p {
    font-size: 18px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`;

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1;

  @media ${device.laptopL} {
    max-width: 341px;
  }

  @media ${device.mobileL} {
    max-width: 100%;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > img {
  }

  > p {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const Texts = styled.p`
  font-size: 18px;
  text-align: center;
`;
