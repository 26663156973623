import { styled } from "styled-components";
import size from "../../theme/devices/sizes";
import device from "../../theme/devices";

export const Section = styled.section`
  max-width: 1440px;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 96px 160px 96px 0px;
  gap: 140px;

  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;
    padding: 52px 40px;
    gap: 20px;
  }

  @media ${device.mobileL} {
    padding: 32px 0px 52px;
  }
`;

export const IntroImg = styled.img`
  max-width: 620px;
  max-height: 587px;
  align-self: flex-end;

  @media ${device.laptopL} {
    max-width: 80%;
    align-self: center;
  }

  @media ${device.mobileL} {
    align-self: flex-start;
  }
`;

export const Right = styled.div`
  max-width: 520px;
  padding-top: 50px;

  > p {
    font-size: 16px;
    line-height: 26px;
  }

  @media ${device.laptop} {
    max-width: 75%;

    > p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media ${device.mobileL} {
    max-width: 100%;
    padding: 12px 24px;
  }
`;

export const Heading = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
  }
`;

export const Texts = styled.div`
  margin-bottom: 40px;

  > p {
    font-size: 16px;
    line-height: 26px;

    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;
