import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Container, Main, Section } from "./styles";
import ReusableButton from "../../reusable/buttons";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Section>
      <Container>
        <Header $form={true} />
        <Main>
          <h1>404 Not Found</h1>
          <ReusableButton
            $primary={true}
            name="Back To Home"
            onClick={() => handleClick()}
          />
        </Main>
      </Container>
      <Footer />
    </Section>
  );
};

export default NotFoundPage;
