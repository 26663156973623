import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepState {
  step: number;
}

const initialState: StepState = {
  step: 1,
};

const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    resetStep: (state) => {
      state.step = initialState.step;
    },
  },
});

export const { updateStep, resetStep } = stepSlice.actions;
export default stepSlice.reducer;
