import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

interface ButtonProps {
  $primary?: boolean;
  width?: string;
  height?: string;
  $mwidth?: string;
  color?: string;
  fontSize?: string;
}

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => width || "222px"};
  height: ${({ height }) => height || "61px"};
  font-family: "forma-djr-banner", sans-serif;
  font-size: ${({ fontSize }) => fontSize || "18px"};
  font-weight: 500;
  color: ${({ $primary, color }) =>
    $primary ? `${colors.white}` : `${color || colors.primary}`};

  background: ${({ $primary }) =>
    $primary ? `${colors.primary}` : `${colors.white}`};

  border: ${({ $primary }) =>
    $primary ? "none" : `2px solid ${colors.primary}`};

  cursor: pointer;

  &:hover {
    background: ${colors.BtnHover};
  }

  @media ${device.mobileL} {
    width: ${({ $mwidth }) => $mwidth || "100%"};
    height: 59px;
    font-size: ${({ fontSize }) => fontSize || "16px"};
  }
`;
