import React, { Dispatch, SetStateAction, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  Arrow,
  DatePickerContainer,
  Dropdown,
  DropdownWrapper,
  Input,
  InputWrapper,
  Option,
  Selection,
} from "./styles";
import { getYear, getMonth } from "date-fns";
import { range } from "lodash";
import imgs from "../../assets";
import { enGB } from "date-fns/locale";

interface CalenderComProps {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
}

const CalenderCom: React.FC<CalenderComProps> = ({ date, setDate }) => {
  // const [startDate, setStartDate] = useState(new Date());
  const [showMonth, setShowMonth] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const years = range(new Date().getFullYear(), new Date().getFullYear() + 101);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  registerLocale("enGB", enGB);

  return (
    <DatePickerContainer>
      <DatePicker
        locale="enGB"
        className="date-picker"
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Selection>
              <InputWrapper>
                <Input
                  value={months[getMonth(date)]}
                  readOnly
                  onClick={() => setShowMonth(!showMonth)}
                />
                <Arrow
                  src={imgs.orangeTriangle}
                  alt="triangle"
                />
              </InputWrapper>

              {showMonth && (
                <DropdownWrapper>
                  <Dropdown>
                    {months.map((option, i) => (
                      <Option
                        key={option}
                        value={option}
                        onClick={() => {
                          changeMonth(i);
                          setShowMonth(!showMonth);
                        }}
                      >
                        {option}
                      </Option>
                    ))}
                  </Dropdown>
                </DropdownWrapper>
              )}
            </Selection>
            <Selection>
              <InputWrapper>
                <Input
                  value={years.find((el) => el === getYear(date))}
                  readOnly
                  onClick={() => setShowYear(!showYear)}
                />
                <Arrow
                  src={imgs.orangeTriangle}
                  alt="triangle"
                />
              </InputWrapper>

              {showYear && (
                <DropdownWrapper>
                  <Dropdown>
                    {years.map((option, i) => (
                      <Option
                        key={option}
                        value={option}
                        onClick={() => {
                          changeYear(option);
                          setShowYear(!showYear);
                        }}
                      >
                        {option}
                      </Option>
                    ))}
                  </Dropdown>
                </DropdownWrapper>
              )}
            </Selection>
          </div>
        )}
        selected={date}
        onChange={(date: any) => setDate(date)}
        inline
      />
    </DatePickerContainer>
  );
};

export default CalenderCom;
