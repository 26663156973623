import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Details {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businessName: string;
  businessAddress: string;
  businessPostcode: string;
}

interface FormState {
  currentTurnover: string;
  overSixstaffs: boolean;
  leaseRunOutDate: string;
  monthlyRent: string;
  details: Details;
}

const initialState: FormState = {
  currentTurnover: "",
  overSixstaffs: false,
  leaseRunOutDate: "",
  monthlyRent: "",
  details: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    businessAddress: "",
    businessPostcode: "",
  },
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    updateForm: (state, action: PayloadAction<Partial<FormState>>) => {
      return (state = { ...state, ...action.payload });
    },
    resetForm: () => {
      return initialState;
    },
  },
});

export const { updateForm, resetForm } = formSlice.actions;
export default formSlice.reducer;
