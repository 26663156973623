import { configureStore } from "@reduxjs/toolkit";
import stepReducer from "./features/stepSlice";
import formReducer from "./features/formSlice";
import progressReducer from "./features/progressSlice";

const store = configureStore({
  reducer: {
    step: stepReducer,
    form: formReducer,
    progress: progressReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
