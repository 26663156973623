import { styled } from "styled-components";
import { ReactComponent as poundIcon } from "../../../assets/icons/pound.svg";
import device from "../../../theme/devices";
import { colors } from "../../../theme/colors";

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 56px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
    margin-bottom: 40px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 56px;

  @media ${device.mobileL} {
    width: 90%;
    margin: 0 auto 40px;
  }
`;

export const Input = styled.input`
  width: 300px;
  height: 44px;
  border: 1px solid ${colors.grey};
  padding-left: 30px;

  @media ${device.mobileL} {
    width: 100%;
    height: 42px;
  }
`;

export const Pound = styled(poundIcon)`
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  width: 7.7px;
  height: 11.5px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 270px;
`;
