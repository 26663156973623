import { styled } from "styled-components";
import imgs from "../../assets";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Section = styled.section``;

export const Container = styled.div`
  background-image: url(${imgs.formBgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 80vh;

  padding: 20px 80px 81px;

  @media ${device.tabletL} {
    padding: 40px 0px 0px;
  }
`;

export const Main = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 56px;

  > h1 {
    color: ${colors.white};
  }
`;
