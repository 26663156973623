import heroBg from "./bgs/heroBg.png";
import logo from "./icons/logo.svg";
import location from "./icons/location.svg";
import bannerIcon from "./icons/bannerIcon.svg";
import secureSSL from "./icons/secureSSL.svg";
import intro from "./bgs/intro.png";
import stepIcon1 from "./icons/stepIcon1.svg";
import stepIcon2 from "./icons/stepIcon2.svg";
import stepIcon3 from "./icons/stepIcon3.svg";
import guarantee from "./bgs/guarantee.png";
import logoBlack from "./icons/logoBlack.svg";
import opopLogo from "./icons/opopLogo.svg";
import formBgDesktop from "./bgs/formBgDesktop.png";
import pound from "./icons/pound.svg";
import thankyouIcon from "./icons/thankyouIcon.svg";
import ceo from "./icons/ceo.png";
import cfo from "./icons/cfo.png";
import salesHead from "./icons/salesHead.png";
import adviser from "./icons/adviser.png";
import review1 from "./icons/review1.svg";
import review2 from "./icons/review2.svg";
import review3 from "./icons/review3.svg";
import orangeTriangle from "./icons/orangeTriangle.svg";
import bullet from "./icons/bullet.svg";

import david from "./icons/david.png";
import marc from "./icons/marc.png";

const imgs = {
  heroBg,
  bullet,
  david,
  marc,
  logo,
  location,
  bannerIcon,
  secureSSL,
  intro,
  stepIcon1,
  stepIcon2,
  stepIcon3,
  guarantee,
  logoBlack,
  opopLogo,
  formBgDesktop,
  pound,
  thankyouIcon,
  ceo,
  cfo,
  salesHead,
  adviser,
  review1,
  review2,
  review3,
  orangeTriangle,
};

export default imgs;
