import { useEffect } from "react";
import Header from "../../components/Header";
import Sorry from "../../components/Sorry";
import { Inner, Section } from "./styles";
import useCheckPath from "../../utils/useCheckPath";
import { useNavigate } from "react-router-dom";

const SorryPage = () => {
  const navigate = useNavigate();
  const valid = useCheckPath();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!valid) {
      navigate("/");
    }
  });

  return (
    <Section>
      <Header $form={true} />
      <Inner>
        <Sorry />
      </Inner>
    </Section>
  );
};

export default SorryPage;
