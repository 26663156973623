import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import { colors } from "../../theme/colors";
import { Container, Icon, Main, Section, Step, Title } from "./styles";

interface StepsSectionProps {
  handleHeroScroll: () => void;
}

const StepsSection: React.FC<StepsSectionProps> = ({ handleHeroScroll }) => {
  return (
    <Section>
      <Container>
        <Title>It only takes 3 easy steps.</Title>
        <Main>
          <Step>
            <Icon
              src={imgs.stepIcon1}
              alt="icon"
            />
            <h3>Step 1</h3>
            <p>
              Provide basic information such as your number of covers, rent and
              profit.
            </p>
          </Step>
          <Step style={{ background: "#ECE7D4", color: `${colors.text}` }}>
            <Icon
              src={imgs.stepIcon2}
              alt="icon"
            />
            <h3>Step 2</h3>
            <p>
              Speak to one of our experts here at the restaurant acquisition
              group to discuss your valuation.{" "}
            </p>
          </Step>
          <Step>
            <Icon
              src={imgs.stepIcon3}
              alt="icon"
            />
            <h3>Step 3</h3>
            <p>
              After agreeing on the details, you’ll be able to sell your
              restaurant to us, with ease!
            </p>
          </Step>
        </Main>
        <ReusableButton
          $primary={true}
          name="Get Started Now"
          onClick={() => handleHeroScroll()}
          $mwidth="215px"
        />
      </Container>
    </Section>
  );
};

export default StepsSection;
