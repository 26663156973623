import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Section = styled.section`
  background: ${colors.tertiary};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 56px;
  padding: 80px 80px;
  max-width: 1440px;
  margin: 0 auto;

  @media ${device.mobileL} {
    padding: 48px 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  > p {
    max-width: 880px;
    text-align: center;

    @media ${device.mobileL} {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;

export const Logo = styled.img`
  width: 183px;
  height: 40px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tabletL} {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 32px;

  a,
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  @media ${device.mobileL} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px 32px;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const OpopLogo = styled.img`
  width: 311px;
  height: 32px;

  @media ${device.mobileL} {
    width: 258px;
    height: 24px;
  }
`;
