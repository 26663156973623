import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import {
  Container,
  ImgWrapper,
  Main,
  Review,
  Section,
  Texts,
  Title,
} from "./styles";

interface ReviewsProps {
  handleHeroScroll: () => void;
}

const ReviewsSection: React.FC<ReviewsProps> = ({ handleHeroScroll }) => {
  return (
    <Section>
      <Container>
        <Title>
          <h1>Trusted by restaurants of all sizes.</h1>
          <p>
            From small, independent eateries to large chain restaurants, our
            team has been entrusted by hundreds of UK-based owners to quickly
            and easily help them sell their restaurant at a price they deserve
            for their hard work.
          </p>
        </Title>
        <Main>
          <Review>
            <ImgWrapper>
              <img
                src={imgs.review1}
                alt="reviewer"
              />
              <p>Harrison Ardwick, Yorkshire</p>
            </ImgWrapper>
            <Texts>
              “The Cost of Living crisis forced me to evaluate my options: I’m
              immensely thankful for the Restaurant Acquisition Group for their
              help in making selling my business a seamless and easy process.”
            </Texts>
          </Review>
          <Review>
            <ImgWrapper>
              <img
                src={imgs.review2}
                alt="reviewer"
              />
              <p>Umar Diame, Greater London</p>
            </ImgWrapper>
            <Texts>
              “I knew I struck gold with my restaurant in London, but I was
              seeking a new venture. When I first contacted the Restaurant
              Acquisition Group, they were attentive and knowledgeable from the
              very beginning: I didn’t imagine selling my restaurant would be
              this simple.”
            </Texts>
          </Review>
          <Review>
            <ImgWrapper>
              <img
                src={imgs.review3}
                alt="reviewer"
              />
              <p>Jon Pallett, Cardiff</p>
            </ImgWrapper>
            <Texts>
              “My restaurant was in my family for decades, and when we decided
              to sell up, I knew immediately that the Restaurant Acquisition
              Group would be who I went to first. They took great care of
              something we valued so much, and made sure we got the right amount
              of money for it!”
            </Texts>
          </Review>
        </Main>
        <ReusableButton
          $primary={true}
          name="Sell My Restaurant"
          onClick={() => handleHeroScroll()}
          $mwidth="215px"
        />
      </Container>
    </Section>
  );
};

export default ReviewsSection;
