import { styled } from "styled-components";
import imgs from "../../assets";
import device from "../../theme/devices";
import { colors } from "../../theme/colors";

export const Section = styled.section`
  background-image: url(${imgs.formBgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;

  padding: 20px 80px 81px;

  @media ${device.tabletL} {
    padding: 40px 0px 0px;
  }
`;

export const Inner = styled.div`
  background: ${colors.white};
  max-width: 1440px;
  margin: 0 auto;
  min-height: 630px;
  border: 1px solid black;
  padding: 32px 80px;

  @media ${device.tabletL} {
    border: none;
    padding: 24px 24px;
    min-height: 100vh;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
