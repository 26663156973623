import { styled } from "styled-components";
import device from "../../../theme/devices";

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 56px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
    margin-bottom: 40px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 265px;

  @media ${device.mobileL} {
    flex-direction: column;
    margin-bottom: 277px;
  }
`;
