import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import Thankyou from "../../components/Thankyou";
import { Inner, Section } from "./styles";
import useCheckPath from "../../utils/useCheckPath";
import { useEffect } from "react";

const ThankyouPage = () => {
  const navigate = useNavigate();
  const valid = useCheckPath();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!valid) {
      navigate("/");
    }
  });

  return (
    <Section>
      <Header $form={true} />
      <Inner>
        <ProgressBar />
        <Thankyou />
      </Inner>
    </Section>
  );
};

export default ThankyouPage;
