import { styled } from "styled-components";
import device from "../../theme/devices";
import { colors } from "../../theme/colors";
import { ReactComponent as BannerIconSvg } from "../../assets/icons/bannerIcon.svg";

interface BannerProps {
  $form?: boolean;
}

export const BannerWrapper = styled.div<BannerProps>`
  background: ${({ $form }) => !$form && "rgba(236, 231, 212, 0.15)"};
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  width: 100%;
  padding: ${({ $form }) => ($form ? "0px" : "32px 80px")};

  @media ${device.laptop} {
    flex-direction: column;
    padding: ${{}} 24px 0px;
    gap: 24px;
  }

  @media ${device.mobileL} {
    align-items: ${({ $form }) => ($form ? "center" : "flex-start")};
    margin-top: ${({ $form }) => !$form && "48px"};
    padding: ${({ $form }) => ($form ? "0px" : "24px 24px")};
  }
`;

export const BannerItem = styled.div<BannerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  > p {
    color: ${({ $form }) => ($form ? `${colors.text}` : `${colors.white}`)};
    font-size: 18px;

    @media ${device.mobileL} {
      font-size: 14px;
    }
  }
`;

export const BannerIcon = styled(BannerIconSvg)<BannerProps>`
  width: 40px;
  height: 40px;

  ${({ $form }) =>
    $form &&
    `path {
    fill: ${colors.text};
  }`}

  @media ${device.mobileL} {
    width: 24px;
    height: 24px;
  }
`;
