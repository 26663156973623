import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import { Container, Heading, IntroImg, Right, Section, Texts } from "./styles";

interface IntroSectionProps {
  handleHeroScroll: () => void;
}

const IntroSection: React.FC<IntroSectionProps> = ({ handleHeroScroll }) => {
  return (
    <Section>
      <Container>
        <IntroImg
          src={imgs.intro}
          alt="background"
        />

        <Right>
          <Heading>Free up your time for life’s important things.</Heading>
          <Texts>
            <p>
              Owning a hospitality business can be rewarding. With its high
              demands and time-consuming effort every day in order to make your
              restaurant work, you’ve given up a lot.
            </p>
            <br />
            <p>
              Selling your restaurant can be an emotional thing, that’s why it’s
              sometimes difficult to obtain a fair price for it, and you deserve
              to reap the rewards in full when you decide to move on from your
              current venture.
            </p>
            <br />
            <p>
              RAG recognises the life and soul you’ve put into your restaurant
              and offers you the best price possible for your hard work, freeing
              up your time for whatever you want to do.
            </p>
            <br />
            <p>
              Start your free valuation today and a member of our team will look
              to help as quickly as possible with selling your business at a
              truly brilliant price that benefits you the most.
            </p>
          </Texts>
          <ReusableButton
            $primary={true}
            name="Start Free Valuation"
            onClick={() => handleHeroScroll()}
            $mwidth="215px"
          />
        </Right>
      </Container>
    </Section>
  );
};

export default IntroSection;
