import { styled } from "styled-components";
import device from "../../../theme/devices";

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 50px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
    margin-bottom: 40px;
  }
`;
export const ButtonWrapper = styled.div`
  margin: 56px auto 75px;

  @media ${device.mobileL} {
    margin: 40px auto 75px;
  }
`;
