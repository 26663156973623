import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProgressState = number;

const initialState: ProgressState = 17;

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    updateProgress: (state, action: PayloadAction<number>) => {
      return (state = action.payload);
    },
    resetProgress: (state) => {
      return (state = initialState);
    },
  },
});

export const { updateProgress, resetProgress } = progressSlice.actions;
export default progressSlice.reducer;
