import { css, styled } from "styled-components";

interface ContainerProps {
  preventHover?: boolean;
}

export const QuestionContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ preventHover }) =>
    preventHover &&
    css`
      pointer-events: none;
    `}/* ${({ preventHover }) =>
    preventHover &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `} */
`;

export const StepWrapper = styled.div``;
