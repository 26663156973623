import { styled } from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
  }
`;

export const SubTitle = styled.p`
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #514945;
  margin-bottom: 56px;

  @media ${device.mobileL} {
    font-size: 14px;
    letter-spacing: 0.28px;
    margin-bottom: 40px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  width: 520px;
  margin-bottom: 56px;

  @media ${device.mobileL} {
    gap: 16px;
    width: 100%;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 44px;
  border: 1px solid ${colors.grey};
  font-size: 14px;
  padding-left: 15px;

  @media ${device.mobileL} {
    height: 42px;
  }
`;

export const AddressWrapper = styled.div`
  position: relative;
`;

export const AddressInput = styled.input`
  width: 100%;
  height: 44px;
  font-size: 14px;

  &::placeholder {
    padding-left: 15px;
  }
`;

export const SearchBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary};
  color: ${colors.white};
  width: 71px;
  height: 90%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;

  border: none;
  padding: 14px;

  @media ${device.mobileL} {
    font-size: 12px;
    width: 65px;
    height: 36px;
    letter-spacing: 0.24px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  top: 100%;
  height: 180px;
  overflow: scroll;

  @media ${device.mobileL} {
    height: 160px;
  }
`;

export const Dropdown = styled.ul`
  list-style-type: none;

  > li {
    padding: 10px 10px;
    border-bottom: 1px solid ${colors.grey};
    cursor: pointer;
  }
`;

export const SelectedAddress = styled.p`
  padding: 14px;
  border: 1px solid ${colors.grey};
`;

export const ChangeAddress = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 204px;

  @media ${device.mobileL} {
    margin-bottom: 224px;
  }

  > p {
    color: #514945;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;

    @media ${device.mobileL} {
      font-size: 12px;
      letter-spacing: 0.24px;
    }
  }
`;
