import { useAppSelector } from "../../redux/hooks";
import { Bar, Wrapper } from "./styles";

const ProgressBar = () => {
  const { progress } = useAppSelector((state) => state);

  return (
    <Wrapper $progress={progress}>
      <Bar $progress={progress}></Bar>
    </Wrapper>
  );
};

export default ProgressBar;
