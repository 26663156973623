import imgs from "../../assets";
import {
    Bottom,
    Container,
    Links,
    Logo,
    OpopLogo,
    Section,
    Top,
} from "./styles";

const Footer = () => {
    return (
        <Section>
            <Container>
                <Top>
                    <Logo src={imgs.logoBlack} alt="logo" />
                </Top>
                <Bottom>
                    <Links>
                        <a href="/legal/privacy.pdf" target="_blank">
                            Privacy Policy
                        </a>
                        <a href="/legal/terms.pdf" target="_blank">
                            Terms & Conditions
                        </a>
                        {/* <a href="/">Cookie Policy</a> */}
                    </Links>
                    <OpopLogo src={imgs.opopLogo} alt="opop-logo" />
                </Bottom>
            </Container>
        </Section>
    );
};

export default Footer;
