import { BannerIcon, BannerWrapper, BannerItem } from "./styles";

interface BannerProps {
  $form?: boolean;
}

const Banner: React.FC<BannerProps> = ({ $form }) => {
  return (
    <BannerWrapper $form={$form}>
      <BannerItem $form={$form}>
        <BannerIcon $form={$form} />
        <p>
          <strong>Fairest price</strong> on the market
        </p>
      </BannerItem>
      <BannerItem $form={$form}>
        <BannerIcon $form={$form} />
        <p>Highly-experienced & caring team</p>
      </BannerItem>
      <BannerItem $form={$form}>
        <BannerIcon $form={$form} />
        <p>
          Have <strong>more time</strong> for family life
        </p>
      </BannerItem>
    </BannerWrapper>
  );
};

export default Banner;
