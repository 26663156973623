import imgs from "../../assets";
import size from "../../theme/devices/sizes";
import useWindowSize, { Size } from "../../utils/useWindowSize";
import { Container, Logo, Title, SecureIcon, RightWrapper } from "./styles";

interface HeaderProps {
  $form: boolean;
  handleGuaranteeScroll?: () => void;
}

const Header: React.FC<HeaderProps> = ({ $form, handleGuaranteeScroll }) => {
  const windowSize: Size = useWindowSize();
  const laptop = parseInt(size.laptop.slice(0, -2));

  return (
    <Container $form={$form}>
      <a href="/">
        <Logo
          src={imgs.logo}
          alt="logo"
        />
      </a>

      {windowSize.width! > laptop && !$form && (
        <Title>
          Achieve a <strong>fair price</strong> for trading your hospitality
          business
        </Title>
      )}
      <RightWrapper>
        {!$form && <p onClick={handleGuaranteeScroll}>About Us</p>}
        <SecureIcon
          src={imgs.secureSSL}
          alt="ssl-logo"
        />
      </RightWrapper>
    </Container>
  );
};

export default Header;
