import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";
import size from "../../theme/devices/sizes";

export const Section = styled.section`
  background: ${colors.brown};
  color: ${colors.tertiary};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  padding: 80px 144px 48px;
  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.laptopL} {
    padding: 80px 40px 48px;
  }

  @media ${device.mobileL} {
    gap: 32px;
    padding: 56px 24px 48px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  letter-spacing: 0.96px;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
    text-align: center;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tabletL} {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 46px 32px;
  max-width: 352px;
  text-align: center;

  @media ${device.mobileL} {
    padding: 24px 16px;
  }

  h3 {
    font-size: 32px;
    letter-spacing: 0.64px;

    @media ${device.mobileL} {
      font-size: 24px;
      letter-spacing: 0.48px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;

    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media ${device.tabletL} {
    max-width: 100%;
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
