import { useState } from "react";
import { QuestionContainer } from "../styles";
import { ButtonWrapper, Input, InputWrapper, Pound, Title } from "./styles";
import { useDispatch } from "react-redux";
import { updateForm } from "../../../redux/features/formSlice";
import { updateStep } from "../../../redux/features/stepSlice";
import { updateProgress } from "../../../redux/features/progressSlice";
import ReusableButton from "../../../reusable/buttons";
import { Notify } from "notiflix";

const Step4 = () => {
  const dispatch = useDispatch();
  const [rent, setRent] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimValue = value.replace(/\D/g, "");
    setRent(trimValue);
  };

  const handleContinue = () => {
    if (rent.length === 0) {
      return Notify.failure("Please provide your rent.");
    } else {
      dispatch(updateForm({ monthlyRent: rent }));
      dispatch(updateStep(5));
      dispatch(updateProgress(85));
    }
  };

  return (
    <QuestionContainer>
      <Title>How much do you pay in rent per month?</Title>
      <InputWrapper>
        <Pound />
        <Input
          placeholder="1200"
          name="rent"
          value={rent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          inputMode="numeric"
        />
      </InputWrapper>
      <ButtonWrapper>
        <ReusableButton
          $primary={true}
          name="Continue"
          $mwidth="207px"
          onClick={() => handleContinue()}
        />
      </ButtonWrapper>
    </QuestionContainer>
  );
};

export default Step4;
