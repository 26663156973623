import { useNavigate } from "react-router-dom";
import imgs from "../../assets";
import ReusableButton from "../../reusable/buttons";
import { Container, Icon, SubTitle, Title } from "./styles";

const Thankyou = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Container>
      <Icon
        src={imgs.thankyouIcon}
        alt="tick"
      />
      <Title>Thank you for your enquiry!</Title>
      <SubTitle>
        Thank you, a member of the Restaurant Acquisition Group will be in touch
        soon.
      </SubTitle>
      <ReusableButton
        $primary={true}
        name="Back To Home"
        onClick={() => handleClick()}
      />
    </Container>
  );
};

export default Thankyou;
