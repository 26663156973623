import { styled } from "styled-components";
import imgs from "../../assets";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Section = styled.section``;

export const Container = styled.div`
  background-image: url(${imgs.heroBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  transition: background-image 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 80px 74px;

  @media ${device.tabletL} {
    padding: 40px 0px 0px;
  }
`;

export const SubTitle = styled.p`
  background: rgba(236, 231, 212, 0.15);

  font-family: "forma-djr-banner", sans-serif;
  font-size: 18px;
  letter-spacing: 0.36px;
  text-align: center;
  color: ${colors.white};
  border: 1px solid ${colors.primary};
  padding: 16px 16px;

  @media ${device.tabletL} {
    padding: 16px 12px;
    margin: 16px 10px;
    width: 80%;
  }

  @media ${device.mobileL} {
    width: 95%;
    font-size: 14px;
    letter-spacing: 0.28px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  letter-spacing: 0.96px;
  color: ${colors.white};
  margin-bottom: 40px;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 32px;
    letter-spacing: 0.64px;
    padding: 0px 24px;
    margin-bottom: 32px;
  }
`;

export const InputLabel = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${colors.white};
  margin-bottom: 24px;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 672px;
  height: 67px;
  margin-bottom: 64px;

  @media ${device.tabletL} {
    width: 80%;
    margin: 0 auto 64px;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-bottom: 16px;
    padding: 0px 24px;
  }
`;

export const PostcodeInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  padding-left: 60px;

  @media ${device.mobileL} {
    font-size: 16px;
    padding-left: 50px;
  }
`;

export const LocationIcon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);

  @media ${device.mobileL} {
    left: 40px;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  @media ${device.mobileL} {
    right: 0;
    top: 0;
    position: static;
    width: 100%;
    padding: 0px 24px;
  }
`;
