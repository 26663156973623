import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const DatePickerContainer = styled.div`
  .react-datepicker {
    border: 2px solid ${colors.primary} !important;
    border-radius: 0px;
    transform: scale(1.1);

    @media ${device.laptopL} {
      transform: scale(1);
    }
  }

  .react-datepicker__header {
    background: ${colors.white};
    border: none;
    padding: 0;

    > div {
      > select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
      }
    }
  }

  .react-datepicker__month-container {
    padding: 10px 24px;
  }

  .react-datepicker__month {
    margin: 0 auto;
  }

  .react-datepicker__day-names {
    margin: 0 auto;
  }

  .react-datepicker__day--selected {
    background: ${colors.primary};
  }
`;

export const Selection = styled.div`
  position: relative;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 23px;
  left: 0px;
  width: 93px;
`;

export const Dropdown = styled.ul`
  background: ${colors.white};
  list-style-type: none;
  border: 1px solid ${colors.grey};
  height: 150px;
  overflow: scroll;
  cursor: pointer;
`;

export const Option = styled.li`
  padding-left: 10px;
  text-align: left;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  border: none;
  width: 93px;
  cursor: pointer;
  outline: none;
  padding-left: 10px;
`;

export const Arrow = styled.img`
  position: absolute;
  top: 8px;
  right: 5px;
  pointer-events: none;
`;
