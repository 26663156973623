import store from "../redux/store";

const useCheckPath = () => {
  const { businessPostcode } = store.getState().form.details;

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/sorry" &&
    businessPostcode.length === 0
  ) {
    return false;
  } else {
    return true;
  }
};

export default useCheckPath;
