import ReusableButton from "../../../reusable/buttons";
import { Title, OptionsWrapper } from "./styles";
import { useDispatch } from "react-redux";
import { updateForm } from "../../../redux/features/formSlice";
import { updateStep } from "../../../redux/features/stepSlice";
import { updateProgress } from "../../../redux/features/progressSlice";
import { QuestionContainer } from "../styles";
import { useNavigate } from "react-router-dom";

const Step2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (value === "No") {
      navigate("/sorry");
    } else {
      dispatch(updateForm({ overSixstaffs: value === "Yes" ? true : false }));
      dispatch(updateStep(3));
      dispatch(updateProgress(51));
    }
  };

  return (
    <QuestionContainer>
      <Title>Do you have over 6 members of staff?</Title>
      <OptionsWrapper>
        <ReusableButton
          $primary={true}
          name="Yes"
          value="Yes"
          width="222px"
          height="61px"
          onClick={(e) => handleClick(e)}
        />
        <ReusableButton
          name="No"
          value="No"
          width="222px"
          height="61px"
          onClick={(e) => handleClick(e)}
        />
      </OptionsWrapper>
    </QuestionContainer>
  );
};

export default Step2;
