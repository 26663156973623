import { useNavigate } from "react-router-dom";
import ReusableButton from "../../reusable/buttons";
import { Container, SubTitle, Title } from "./styles";

const Sorry = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Container>
      <Title>
        Sorry, you won’t be able to sell your business to us at this time.
      </Title>
      <SubTitle>
        If what you selected was wrong or a mistake, please try again below.
      </SubTitle>
      <ReusableButton
        $primary={true}
        name="Start Over"
        onClick={() => handleClick()}
      />
    </Container>
  );
};

export default Sorry;
