import { useEffect } from "react";
import "./App.css";
import { useAppSelector } from "./redux/hooks";
import AppRouter from "./router";

declare global {
  interface Window {
    predictiveAddress: any;
    data8: any;
  }
}

function App() {
  const form = useAppSelector((state) => state.form);

  useEffect(() => {
    console.log(form, "<<< FORM");
  }, [form]);

  return <AppRouter />;
}

export default App;
