import { styled } from "styled-components";
import device from "../../theme/devices";
import { colors } from "../../theme/colors";

interface HeaderProps {
  $form?: boolean;
}

export const Container = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0px;
  margin: 0 auto ${({ $form }) => ($form ? "33px" : "98px")};

  @media ${device.laptopL} {
    margin-bottom: 32px;
  }

  @media ${device.tabletL} {
    padding: ${({ $form }) => ($form ? "0px 24px" : "0px 80px")};
  }

  @media ${device.mobileL} {
    padding: 0px 24px;
    margin-bottom: ${({ $form }) => ($form ? "24px" : "16px")};
  }
`;

export const Logo = styled.img`
  width: 146px;
  height: 32px;

  @media ${device.mobileL} {
    width: 110px;
    height: 24px;
  }
`;

export const Title = styled.p`
  background: rgba(236, 231, 212, 0.15);

  font-family: "forma-djr-banner", sans-serif;
  font-size: 18px;
  letter-spacing: 0.36px;
  text-align: center;
  color: ${colors.white};
  border: 1px solid ${colors.primary};
  padding: 16px 16px;

  @media ${device.tabletL} {
    padding: 16px 12px;
    margin: 16px 10px;
    width: 95%;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    letter-spacing: 0.28px;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;

  > p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
    padding: 14px;
    cursor: pointer;
  }
`;

export const SecureIcon = styled.img`
  width: 119px;
  height: 32px;

  @media ${device.mobileL} {
    width: 89px;
    height: 24px;
  }
`;
