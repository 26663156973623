import ReusableButton from "../../../reusable/buttons";
import { Title, Option, OptionsWrapper } from "./styles";
import { useDispatch } from "react-redux";
import { updateForm } from "../../../redux/features/formSlice";
import { updateStep } from "../../../redux/features/stepSlice";
import { updateProgress } from "../../../redux/features/progressSlice";
import { QuestionContainer } from "../styles";
import { useEffect, useState } from "react";

const Step1: React.FC = () => {
  const dispatch = useDispatch();
  const [preventHover, setPreventHover] = useState<boolean>(true);

  const options = [
    "Up to £250k",
    "£251k - £500k",
    "£501k - £1million",
    "Over £1million",
  ];

  useEffect(() => {
    setTimeout(() => {
      setPreventHover(false);
    }, 1000);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    dispatch(updateForm({ currentTurnover: value }));
    dispatch(updateStep(2));
    dispatch(updateProgress(34));
  };

  return (
    <QuestionContainer preventHover={preventHover}>
      <Title>What’s your current turnover?</Title>
      <OptionsWrapper>
        {options.map((option, index) => {
          return (
            <Option key={index}>
              <ReusableButton
                name={option}
                value={option}
                width="188px"
                height="61px"
                onClick={(e) => handleClick(e)}
              />
            </Option>
          );
        })}
      </OptionsWrapper>
    </QuestionContainer>
  );
};

export default Step1;
