import { styled } from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

interface ProgressBarProps {
  $progress: number;
}

export const Wrapper = styled.div<ProgressBarProps>`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  height: 24px;
  padding: 4px;
  border: 1px solid
    ${({ $progress }) =>
      $progress === 100 ? `${colors.green}` : `${colors.secondary}`};
  margin: 32px auto 75px;

  @media ${device.tabletL} {
    margin: 24px auto 40px;
  }

  @media ${device.mobileL} {
    height: 16px;
    margin: 0px auto 40px;
  }
`;

export const Bar = styled.div<ProgressBarProps>`
  width: ${({ $progress }) => $progress + "%"};
  background: ${({ $progress }) =>
    $progress === 100 ? `${colors.green}` : `${colors.secondary}`};
  height: 100%;
  margin: auto 0;
  transition: width 0.3s ease-in-out;
`;
